import chatbot from "./chatbot.json"
import common from "./common.json"
import declined from "./declined.json"
import expired from "./expired.json"
import header from "./header.json"
import new_quote from "./new_quote.json"
import page_not_found from "./page_not_found.json"
import quote from "./quote.json"
import timeline from "./timeline.json"
import titles from "./titles.json"


export default {
    chatbot,
    common,
    declined,
    expired,
    header,
    new_quote,
    page_not_found,
    quote,
    timeline,
    titles
}
