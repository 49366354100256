import italian from "./it"
import english from "./en"
import german from "./de"
import spanish from "./es"
import french from "./fr"
import japanese from "./ja"
import korean from "./ko"
import dutch from "./nl"
import portuguese from "./pt"
import russian from "./ru"
import turkish from "./tr"
import chinese  from "./zh"

export const getAvailableNamespaces = (requestedNamespace) => {
    const namespaces = ["chatbot", "common", "declined", "expired", "header", "new_quote", "page_not_found", "quote", "timeline", "titles"]
    const filteredNamespaces = Array.isArray(requestedNamespace) && namespaces.filter( namespace => requestedNamespace.includes(namespace))

    return filteredNamespaces.length > 0 ?
        filteredNamespaces :
        namespaces
}

export default {
    "en": english,
    "it": italian,
    "de": german,
    "es": spanish,
    "fr": french,
    "ja": japanese,
    "ko": korean,
    "nl": dutch,
    "pt": portuguese,
    "ru": russian,
    "tr": turkish,
    "zh": chinese
}


