import React, {useEffect} from "react"
import {BrowserRouter as Router, Switch} from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import {createBrowserHistory} from "history"
import routes from "./routes"
import theme from "../src/theme"
import {useDispatch, useSelector} from "react-redux"
import {ThemeProvider} from "react-jss"
import {setLanguage} from "./store/appGenerics.js"
import * as Sentry from "@sentry/react"
import {Integrations} from "@sentry/tracing"
import {serviceThemeSelector} from "./store/shooting"
import dayjs from "dayjs"
import de from "dayjs/locale/de"
import en from "dayjs/locale/en"
import es from "dayjs/locale/es"
import fr from "dayjs/locale/fr"
import it from "dayjs/locale/it"
import ja from "dayjs/locale/ja"
import ko from "dayjs/locale/ko"
import nl from "dayjs/locale/nl"
import pt from "dayjs/locale/pt"
import ru from "dayjs/locale/ru"
import zh from "dayjs/locale/zh"

export const history = createBrowserHistory({basename: "/"})

const App = () => {
    const dispatch = useDispatch()
    const serviceTheme = useSelector(serviceThemeSelector)

    useEffect(() => {
        Sentry.init({
            dsn: "https://6eacf3cb08bd48ab97678a0dfe1a559e@o1170454.ingest.sentry.io/6269537",
            environment: process.env.REACT_APP_ENVIRONMENT_BUILD,
            integrations: [new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
            })],
            tracesSampleRate: 1.0
        })
    }, [])

    useEffect(() => {
        const localStorageLanguage = localStorage.getItem("i18nextLng")

        dispatch(setLanguage(localStorageLanguage))
        dayjs.locale(localStorageLanguage)
    }, [dispatch])

    return (
        <ThemeProvider theme={theme(serviceTheme)}>
            <Router history={history} basename={"/"}>
                <Switch>
                    {routes.map(({routeType: RouteComponent, ...rest}, index) => (
                        <RouteComponent {...rest} key={index}/>
                    ))}
                </Switch>
            </Router>
        </ThemeProvider>
    )
}

export default App
