import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {httpGetShootRedirect} from "../httpRequests/shoot";
import {errorHandler} from "../helpers/utils";
import Spinner from "../components/Spinner";
import {useDispatch} from 'react-redux'
import Snackbar from '../components/Snackbar'

const RedirectToElevate = () => {
    const dispatch = useDispatch()
    const {code, password, media} = useParams()
    const [redirectUrl,setRedirectUrl] = useState("")

    useEffect(() => {
        initShoot()
    }, [])

    const initShoot = async() => {
        try {
            const {data:{url}} = await httpGetShootRedirect(code, password, media)
            setRedirectUrl(url)
            setTimeout(() => {
                window.location.replace(url);
            },1500)
        } catch(e) {
            errorHandler(e, dispatch)
        }
    }
    return <div className={"container h-100 align-items-center d-flex"}>
        <Snackbar/>
        <div className="row">
            <div className="col-12 d-flex flex-column justify-content-center" style={{gap: 16}}>
                <Spinner size={40} overlay={false} center={false}/>
                <h1 style={{color:"#101828",  fontSize:32, fontWeight: 600}}>Just a few moments...</h1>
                <p style={{color:"#475467",  fontSize:16}}>You are about to get redirected to the new Platform</p>
                <p style={{marginTop: 24, fontSize:14}}>Nothing happens?
                    <a href={redirectUrl} style={{marginLeft: 4, color:"#E31B54", fontWeight: 500}}>Click here</a>
                </p>
            </div>
        </div>
    </div>
}

export default RedirectToElevate
