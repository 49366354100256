import {createUseStyles} from "react-jss"

export default createUseStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "initial",
        justifyContent: "center",
        minHeight: "100%",
        padding: [theme.spacing * 6, theme.spacing],
        "& h3": {
            color: theme.colors.black,
            fontSize: theme.spacing * 6,
            fontWeight: 700
        },
        "& p": {
            fontSize: 18,
            maxWidth: 400
        },
        "& small": {
            maxWidth: 400,
            color: theme.colors.green,
            fontSize: theme.spacing * 1.5,
            fontWeight: 700
        }
    }
}))