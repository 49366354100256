export const routes = {
	QUOTE: '/quote/:code/:password',
	QUOTE_MEDIA: '/quote/:code/:password/:media',
	NEW_QUOTE: '/newquote',
	PAGE_NOT_FOUND: '/404'
}

export const quoteViews = {
	SENT: 'sent',
	CANCELED_BY_HOST: 'canceled_by_host',
	DECLINED: 'declined',
	EXPIRED: 'expired'
}

export const coordinationStatuses = {
	MANUAL_COORDINATION: 'manual_coordination',
	HOST_IS_UNRESPONSIVE: 'host_is_unresponsive',
	HOST_IS_NOT_READY: 'host_is_not_ready',
	NO_DATES_FROM_HOSTS: 'no_dates_from_hosts',
	SHOOT_SCHEDULED: 'shoot_scheduled',
	HOST_SHARED_DATES: 'host_shared_dates'
}

export const statuses = {
    LOADING: "loading",
    ACCEPT: "accept",
    PROGRESS: "progress",
    EXPIRED: "expired",
    DECLINED: "declined"
}

export const quoteStatuses = {
    SENT: "sent",
    SIGNED: "signed",
    EXPIRED: 'expired',
    DECLINED: 'declined',
    RENEWED: 'renewed',
	COMPLETED: 'completed',
	CANCELLED: 'cancelled'
}

export const taskStatuses = {
    HARD_CANCELLED: 'hard_cancelled',
    AUTO_CANCELLED: 'auto_cancelled',
    SOFT_CANCELLED: 'soft_cancelled',
    WAITLISTED: 'waitlisted',
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    SCHEDULED: 'scheduled',
    PENDING_ASSETS: 'pending_assets',
    NEEDS_MORE_ASSETS: 'needs_more_assets',
    ASSETS_IN_REVIEW: 'assets_in_review',
    PENDING_PAYMENT: 'pending_payment',
    COMPLETED: 'completed',
    OVERDUE: 'overdue'
}

export const assignmentStatuses = {
    ACCEPTED: "accepted",
    DECLINED: "declined",
    AUTO_CANCELLED: "auto_cancelled",
    SOFT_CANCELLED: "soft_cancelled",
    HARD_CANCELLED: "hard_cancelled",
	PENDING: 'pending'
}

export const themes = {
	RED: 'red',
	DARKGREEN: 'darkGreen',
	VIOLET: 'violet',
	KHAZAN_BLUE: 'khazanBlue',
	ORANGE: 'orange'
}

export const responseTypes = {
	SMALL: 'small',
	REGULAR: 'regular',
	EXTENDED: 'extended'
}

export const hostMatterportActions = {
	ACCEPTED: 'to_accept',
	REMOVED: 'to_remove'
}

export const matterportStatuses = {
	UPLOAD_MEDIABOX: 'upload_to_mediabox',
	READY_QA: 'ready_for_QA',
	PENDING_APPROVAL: 'pending_host_approval',
	REVISION: 'matterport_revision',
	READY_PUBLISH: 'ready_for_upload',
	COMPLETED: 'completed'
}

export const serviceType = {
	HOMES_PRODUCTION: 1,
	WALKTHROUGH: 3
}

//entity_type possible values, useful to define the global entity addressed
export const entityTypes = {
	HOME: 1,
	EXPERIENCES: 2,
	PORTRAITS: 3 //portraits & A4R
}

export const servicesCategories = {
	LUXE: 2,
	PRIVATE_ROOM: 39,
	ONBOARDING: 41,
	JAPAN: 42,
	ADVANCE: 45,
	ONBOARDING_2: 46,
	LUXE_DRONE: 47,
	ECH: 48,
}

export const servicesMedias = {
	PHOTO: 1,
	VIDEO: 2,
	MATTERPORT: 3
}

export const chatbotSources = {
	CHATBOT: 'Chatbot',
	USER: 'User'
}

export const chatbotOptionsTypes = {
	BINARY: 'binary',
	SURVEY: 'survey'
}

export const chatbotReasons = {
	NEW_QUOTE: 'new_quote',
	NEW_QUOTE_MISSING_TITLE: 'new_quote_missing_title',
	WRONG_BEDROOMS: 'wrong_bedrooms',
	PP_UPLOAD_ISSUE: 'pp_upload_issue',
	PP_LATE: 'pp_late',
	PP_UNKNOWN: 'pp_unknown',
	WRONG_LISTING: 'wrong_listing',
	WRONG_LISTING_MISSING_TITLE: 'wrong_listing_missing_title',
	MISSING_IMAGES: 'missing_images',
	OPEN_TICKET: 'open_ticket',
	PHOTOSHOOT_CANCELATION: 'photoshoot_cancelation'
}

export const languages = {
	GERMAN: 'de',
	ENGLISH: 'en',
	SPANISH: 'es',
	FRENCH: 'fr',
	ITALIAN: 'it',
	JAPANESE: 'ja',
	KOREAN: 'ko',
	DUTCH: 'nl',
	PORTUGUESE: 'pt',
	RUSSIAN: 'ru',
	TURKISH: 'tr',
	CHINESE: 'zh'
}
