import {createSelector, createSlice} from '@reduxjs/toolkit'

const appGenerics = createSlice({
    name: 'appGenerics',
    initialState: {
        language: null,
        showSubheader: true,
        alert: {
            message: '',
            level: 'error', // status here can be - success -  warning - error,
            timeout: 0,
            show: false
        }
    },

    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload?.substring(0, 2)
        },
        showAlert: (state, action) => {
            const message = action?.payload?.message
            const level = action?.payload?.level
            state.alert = {message, level, timeout: 6000, show: true}
        },
        hideAlert: (state, action) => {
            const alert = state.alert
            state.alert = {...alert, timeout: 0, show: false}
        },
        showSubheaderToggle: (state, action) => {
            state.showSubheader = action?.payload
        }
    }
})

export default appGenerics.reducer
export const {
    setLanguage,
    showAlert,
    hideAlert,
    showSubheaderToggle
} = appGenerics.actions

const selectSelf = (state) => state.appGenerics
export const languageSelector = createSelector(selectSelf, state => state.language)
