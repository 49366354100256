import {jss} from "react-jss"
import "bootstrap/dist/css/bootstrap.css"
import {themes} from "./helpers/constants"

const theme = (slice) => {
    const newDesignColors = {
        blackAlfa: "#0000001A",
        black0: "#000000",
        black1: "#11181C",
        black2: "#343434",
        black3: "#484848",
        whiteAlfa: "#FFFFFFB3",
        white: "#FFFFFF",
        grey1: "#687076",
        grey2: "#717171",
        grey3: "#B0B0B0",
        grey4: "#D9D9D9",
        grey5: "#DDDDDD",
        grey6: "#F1F3F5",
        red: "#D31E66",
        danger: "#CD2B31",
        success: "#18794E",
        luxe: '#460479',
        redGradient: "linear-gradient(90deg, #E5484D 0%, #E03177 100%)",
        shadow: "0 11px 80px rgba(0, 0, 0, 0.1), 0px 6px 16px rgba(0, 0, 0, 0.12)"
    }

    const newDesignBreakpoints = {
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400
    }
    const newDesignMediaQueries = {
        sm: `@media only screen and (min-width: ${newDesignBreakpoints.sm}px)`,
        md: `@media only screen and (min-width: ${newDesignBreakpoints.md}px)`,
        lg: `@media only screen and (min-width: ${newDesignBreakpoints.lg}px)`,
        xl: `@media only screen and (min-width: ${newDesignBreakpoints.xl}px)`,
        xxl: `@media only screen and (min-width: ${newDesignBreakpoints.xxl}px)`
    }

    const newDesignZIndexes = {
        background: 5,
        section: 10,
        hero: 11,
        header: 20,
        goUpHeader: 21,
        quoteForm: 30,
        modal: 8000,
        spinner: 9000,
        toast: 9999
    }

    const newDesignTypography = {
        h1: {
            fontSize: 24,
            fontWeight: 700,
            color: newDesignColors.black1,
            [newDesignMediaQueries.xl]: {
                fontSize: 42
            }
        },
        h2: {
            fontSize: 20,
            fontWeight: 700,
            color: newDesignColors.black1,
            [newDesignMediaQueries.xl]: {
                fontSize: 36
            }
        },
        h3: {
            fontSize: 18,
            fontWeight: 700,
            color: newDesignColors.black1,
            [newDesignMediaQueries.xl]: {
                fontSize: 24
            }
        },
        p: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.4,
            color: newDesignColors.black1,
            [newDesignMediaQueries.xl]: {
                fontSize: 18
            }
        },
        small: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.4,
            color: newDesignColors.grey1,
            [newDesignMediaQueries.xl]: {
                fontSize: 16
            }
        }
    }


    const spacing = 8
    const mobile = '@media only screen and (max-width: 1024px)'
    const lg = '@media only screen and (max-width: 1199px)'
    const medium = '@media only screen and (max-width:1366px)'
    const middle = '@media only screen and (max-width:640px)'
    const landscape992 = '@media only screen and (max-width:992px)'
    const tablet = '@media only screen and (max-width:767px)'
    const xs = '@media only screen and (max-width:567px)'
    const small = '@media only screen and (max-width:435px)'
    const ie11 = '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)'

    const colors = {
        black: '#222222',
        lightBlack: '#343434',
        darkBlue: '#3451B2',
        grey: '#484848',
        timelineGrey: '#717171',
        textGrey: '#D7D7D7',
        lightGrey: '#F7F7F7',
        white: '#FFFFFF',
        red: '#FF385C',
        lightRed: '#FFDEE4',
        orange: '#EB8D00',
        lightOrange: '#FFF0DA',
        green: '#2FB755',
        lightGreen: '#AAF0BE',
        darkGreen: '#007E82',
        khazanBlue: '#007A87',
        violet: '#460479'
    }

    const serviceColors = {
        [themes.RED]: {
            button: colors[slice],
            popoverButton: colors.black,
            stepCompleted: colors.black
        },
        [themes.VIOLET]: {
            button: 'linear-gradient(90deg, #59086E 0%, #460479 50.05%, #440589 100%)',
            popoverButton: 'linear-gradient(90deg, #59086E 0%, #460479 50.05%, #440589 100%)',
            stepCompleted: '#DACDE4'
        },
        [themes.DARKGREEN]: {
            button: 'linear-gradient(90deg, #BD1E59 0%, #92174D 50.05%, #861453 100%)',
            popoverButton: colors.black,
            stepCompleted: '#96CACC'
        },
        [themes.KHAZAN_BLUE]: {
            button: colors[slice],
            popoverButton: colors.black,
            stepCompleted: colors.black
        },
        [themes.ORANGE]: {
            button: colors[slice],
            popoverButton: colors.black,
            stepCompleted: colors.black
        }
    }

    const themeColor = colors[slice]

    const componentsColors = {
        cardShadow: '0px 4px 16px #E1E1E1',
        inputBorder: '#E4E4E4',
        sidebarHover: '#383838',
        sidebarText: '#989898',
        popoverShadow: '9px 9px 14px rgba(0, 0, 0, 0.08)'
    }

    const label = {
        display: 'block',
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 3,
        textTransform: "capitalized",
        lineHeight: "19px"
    }

    const input = {
        backgroundColor: 'transparent',
        borderTop: "0",
        borderRight: "0",
        borderLeft: "0",
        borderBottom: "1px solid",
        width: "100%",
        height: spacing * 4,
        fontSize: 14,
        padding: [0, spacing],
        color: !slice ? colors.lightBlack : colors.black,
        '&::placeholder': {
            color: colors.darkGrey
        },
        '&:disabled': {
            backgroundColor: 'rgb(0,0,0,0.5)'
        },
        '&:focus': {
            outline: 'none',
            borderBottomColor: colors.black,
            transition: '0.5s'
        }
    }

    const popover = {
        ...componentsColors.modal,
        borderRadius: spacing * 2,
        position: "absolute",
        top: "155px",
        left: "50%",
        zIndex: 12,
        transform: "translateX(-50%)",
        boxShadow: "0 8px 20px 0 rgba(36, 36, 36, 0.12)",
    }

    const flexbox = {
        start: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
        },
        end: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
        },
        center: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        spaceBetween: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }
    }

     const chatBotTheme = {
        background: colors.white,
        fontFamily: "Cereal",
        headerBgColor: colors.black,
        headerFontColor: colors.white,
        headerFontSize: '15px',
        botBubbleColor: colors.lightGrey,
        botFontColor: colors.black,
        userBubbleColor: colors.black,
        userFontColor:  colors.white,
    }

    //globals
    jss.createStyleSheet(
        {
            "@global body": {
                scrollBehavior: "smooth",
                overflowX: "auto",
                overflowY: "auto",
                fontFamily: "Cereal",
                width: "100%",
                minWidth: 320,
                height: "100%",
            },
            "@global h1, h2, h3, p,h4,h5, span, small": {
                "margin-bottom": 0,
            },
            "@global a[data-anchor-unstyled]": {
                "text-decoration": "none",
            },
            "@global a[data-button]": {
                "text-decoration": "none",
            },
            "@global .row": {
                marginLeft: 0,
                marginRight: 0,
            },
            "@global input::placeholder": {
                color: colors.blueGrey,
            },
            //swipe delete
            "@global #delete-container #delete": {
                borderRadius: 25
            },
            //daysPicker
            "@global .daysPicker > .col-md-10":{
                margin: 0,
                width: "100%"
            },

            //new design
            "@global svg": {
                flex: "none"
            },
            "@font-face": [
                {
                    fontFamily: "Cereal",
                    src: `url("https://a0.muscache.com/airbnb/static/airbnb-dls-web/build/fonts/Airbnb_Cereal-Book-9a1c9cca9bb3d65fefa2aa487617805e.woff2") format("woff2"), url("https://a0.muscache.com/airbnb/static/airbnb-dls-web/build/fonts/Airbnb_Cereal-Book-aa38e86e3f98554f9f7053d7b713b4db.woff") format('woff')`,
                    "font-weight": "normal",
                    "font-style": "normal",
                    "font-display": "swap",
                },
                {
                    fontFamily: "Cereal",
                    src: `url("https://a0.muscache.com/airbnb/static/airbnb-dls-web/build/fonts/Airbnb_Cereal-Medium-50fc004b3082375f12ff0cfb67bf8e56.woff2") format("woff2"), url("https://a0.muscache.com/airbnb/static/airbnb-dls-web/build/fonts/Airbnb_Cereal-Medium-4bc8dafd2e0fd8914bf4d5edce9acd24.woff") format('woff')`,
                    "font-weight": "500",
                    "font-style": "normal",
                    "font-display": "swap",
                },
                {
                    fontFamily: "Cereal",
                    src: `url("https://a0.muscache.com/airbnb/static/airbnb-dls-web/build/fonts/Airbnb_Cereal-Bold-bdfb98485e7836ba31b456f65cded088.woff2") format("woff2"), url("https://a0.muscache.com/airbnb/static/airbnb-dls-web/build/fonts/Airbnb_Cereal-Bold-a188841af78481a25b7bb2316a5f5716.woff") format('woff')`,
                    "font-weight": "700",
                    "font-style": "normal",
                    "font-display": "swap",
                }
            ]
        },
        {index: 3, meta: "globals"}
    ).attach()

    const control = {label, input}

    return {
        spacing,
        colors,
        serviceColors,
        themeColor,
        control,
        componentsColors,
        ie11,
        medium,
        middle,
        landscape992,
        mobile,
        tablet,
        lg,
        xs,
        small,
        popover,
        flexbox,
        chatBotTheme,

        newDesignColors,
        newDesignBreakpoints,
        newDesignMediaQueries,
        newDesignTypography,
        newDesignZIndexes
    }
}

export default theme
