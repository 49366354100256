import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import resources from "./translations"
import {getAvailableNamespaces} from './translations'
import {languages} from "./helpers/constants"

export const appLanguages = [
    {code: languages.GERMAN, label: "Deutsch (DE)"},
    {code: languages.ENGLISH, label: "English (EN)"},
    {code: languages.SPANISH, label: "Español (ES)"},
    {code: languages.FRENCH, label: "Français (FR)"},
    {code: languages.ITALIAN, label: "Italiano (IT)"},
    {code: languages.JAPANESE, label: "日本語 (JA)"},
    {code: languages.KOREAN, label: "한국어 (KO)"},
    {code: languages.DUTCH, label: "Nederlands (NL)"},
    {code: languages.PORTUGUESE, label: "Português (PT)"},
    {code: languages.RUSSIAN, label: "Русский (RU)"},
    {code: languages.TURKISH, label: "Türkçe (TR)"}
    //{code: languages.CHINESE, label: "中文 (ZH)"}
]

const i18nextDetectorOptions = {
    order: ['localStorage', 'navigator'],
    lookupLocalStorage: 'i18nextLng',
}

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        ns: getAvailableNamespaces(),
        defaultNS: 'auth',
        keySeparator: false,
        fallbackLng: languages.ENGLISH,
        debug: false,
        react: {
                useSuspense: false,
                wait: false,
            },
        supportedLngs: appLanguages.map(language => language.code),
        nonExplicitWhitelist: true,
        interpolation: {
            escapeValue: false,
        },
        detection: i18nextDetectorOptions,
    })

export default i18n
