import { createUseStyles } from 'react-jss';
import cx from "classnames";
import PropTypes from "prop-types";

const useStyles = createUseStyles(theme => ({
    root: {
        minWidth: ({large}) => large ? 216 : 'max-content',
        padding: ({large}) => large ? "0 8px" : "0 16px",
        height: 48,
        background: ({bgColor, transparent,isSecondary}) => (transparent || isSecondary) ? 'none' : bgColor,
        color: ({color,isSecondary}) => isSecondary ? theme.colors.black : theme.colors[color],
        border:  ({isSecondary}) => isSecondary ? '1px solid #212121' : 0,
        borderRadius: theme.spacing,
        boxShadow: ({color, transparent}) => transparent ? `0px 0px 0px 2px ${theme.colors[color]} inset` : 'none',
        cursor: ({ disabled }) => disabled ? 'not-allowed' : 'pointer',
        fontSize: theme.spacing * 2,
        fontWeight: 700,
        composes: "d-flex justify-content-center align-items-center",
        position: 'relative',
        transition: 'ease-in-out 300ms',
        whiteSpace: 'nowrap',
        outline: '0 !important',
        '& span': {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                height: theme.spacing * 2,
                marginRight: theme.spacing,
            },
        },
        '&:disabled': {
            opacity: 0.6,
            '&:hover': {
                opacity: 0.6
            }
        },
        '&:hover': {
            opacity: 0.9,
        },
        '&[data-small]': {
            height: 36,
            fontSize: 14
        }
    },
}));

const Button = ({ className, children, type = 'button', disabled, icon, bgColor = '#222', color = 'white', transparent= false, large = false,isSecondary = false, ...props }) => {
    const classes = useStyles({ disabled, icon, bgColor, color, transparent, large,isSecondary });

    return <button className={cx(classes.root, className)} {...props} type={type} disabled={disabled}>
            {icon ? <span> {icon} <span>{children}</span></span> : <span>{children}</span>}
        </button>
};

export default Button;

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    type: PropTypes.oneOf(['button', 'submit']),
    disabled: PropTypes.bool,
    icon: PropTypes.node
};
