import React from 'react';
import {Route} from 'react-router-dom';
import AppLayout from "../containers/AppLayout";

const PublicRoute = ({navigation, ...props}) => {

    return <AppLayout>
        <Route {...props} />
    </AppLayout>
}

export default PublicRoute;

