import axios from "axios"
import {responseTypes} from "../helpers/constants"

export const httpGetShoot = (code, password, media) => {
    const url = media ? `/quote/${code}/${password}/${media}` : `/quote/${code}/${password}`

    return axios.get(url, {params: {response_type: responseTypes.EXTENDED}})
}

export const httpGetShootRedirect = (code, password, media) => {
    const url = `/quote/${code}/${password}/${media || '1'}/elevate-url`
    return axios.get(url, {params: {response_type: responseTypes.EXTENDED}})
}

export const httpDeclineQuote = (code, password, media, reason, note) => {
    const url = `/quote/${code}/${password}/${media}/decline`
    const data = {reason, note}

    return axios.post(url, data)
}

export const httpAcceptQuote = (code, password, media) => {
    const url = `/quote/${code}/${password}/${media}/accept`

    return axios.post(url)
}

export const httpRenewQuote = (code, password, media) => {
    const url = `/quote/${code}/${password}/${media}/renew`

    return axios.post(url)
}

export const httpSetQuoteReminder = (code, password, media, date) => {
    const url = `/quote/${code}/${password}/${media}/reminder`
    return axios.post(url, {reminder_at: date})
}

export const httpGetEncryptedQuote = (code) => {
    const url = `/newquote`
    return axios.get(url, { params: { code: code.toString() } })
}

export const httpConfirmMatterportReview = (code, password, images) => {
    const url = `entity/checkHost/${code}/${password}`

    return axios.post(url, {images: images})
}

export const httpGetMatterportInfo = (code, password, params) => {
    const url = `/entity/${code}/${password}`

    return axios.get(url, {params})
}

export const httpGetAmenitiesAutocomplete = () => {
    const url = "/amenity"

    return axios.get(url)
}

export const httpGetRoomsAutocomplete = () => {
    const url = "/rooms"

    return axios.get(url)
}
