import {createUseStyles} from "react-jss"

const useStyles = createUseStyles(theme => ({
    publicLayout: {
        background: theme.colors.white
    },
    content: () => ({
        position: "absolute",
        background: theme.colors.white,
        top: 54,
        left: 0,
        right: 0,
        overflow: "auto",
        padding: 0,
        height: '100vh'
    })
}))

const AppLayout = ({children}) => {
    const classes = useStyles()

    return (
        <main className={classes.publicLayout}>
            <div className={classes.content}>
                {children}
            </div>
        </main>
    )
}

export default AppLayout
