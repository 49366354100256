import React, {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import cx from 'classnames';
import {CloseIcon} from '../assets/icons.js';
import {useDispatch, useSelector} from "react-redux";
import {hideAlert} from "../store/appGenerics";

const useStyles = createUseStyles((theme) => ({
    snackbar: {
        position: 'fixed',
        zIndex: 9999,
        transition: 'ease-in-out 0.5s',
        left: 0,
        right: 0,
        bottom: ({show}) => (show) ? theme.spacing * 2 : -200,
    },
    innerContent: {
        minWidth: theme.spacing * 40,
        width: 'max-content',
        maxWidth: '95%',
        margin: 'auto',
        background: ({level}) => (level === 'success') ? theme.colors.green : (level === 'warning') ? theme.colors.yellow : theme.colors.red,
        color: theme.colors.white,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing,
        fontSize: 14,
        fontWeight: 700,
        padding: theme.spacing * 2,
        borderRadius: theme.spacing
    },
    close: {
        width: 18,
        fill: theme.colors.white,
        cursor: 'pointer'
    },
}));

const Snackbar = () => {
    const alert = useSelector(state => state.appGenerics.alert)
    const {show, message, level, timeout} = alert
    const classes = useStyles({level, show})
    const dispatch = useDispatch()

    useEffect(() => {
        return () => dispatch(hideAlert())
    },[dispatch])

    useEffect(() => {
        if(show) {
            const alertTimeout = setTimeout(() => closeAlert(), timeout);
            return () => clearTimeout(alertTimeout);
        }
    }, [alert]);

    const closeAlert = () => {
        dispatch(hideAlert());
    };

    return <main className={cx(classes.snackbar)}>
        <div className={classes.innerContent}>
            <p>{message}</p>
            <CloseIcon className={classes.close} onClick={closeAlert}/>
        </div>
    </main>
};

export default Snackbar;
