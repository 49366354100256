import useStyles from "./style"
import cx from "classnames"
import Button from "../../components/Button"
import {useTranslation} from "react-i18next"
import {PageNotFoundIcon} from "../../assets/icons"
import PageHelmet from "../../components/PageHelmet"
import {useEffect} from "react"
import {showSubheaderToggle} from "../../store/appGenerics"
import {useDispatch} from "react-redux"

const PageNotFound = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const classes = useStyles()

    useEffect(() => {
        dispatch(showSubheaderToggle(false))
        return () => dispatch(showSubheaderToggle(true))
    }, [])

    return <div className={cx(classes.root, "container text-center")}>
        <PageHelmet title={t("titles:page_not_found")}/>

        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
            <PageNotFoundIcon/>
            <h3 className="mt-5 mb-5">
                {t("page_not_found:title")}
            </h3>
            <Button
                transparent
                color="black"
                large
                onClick={() => window.location.href = `mailto:photography@airbnb.com?subject=Support request`}
            >
                {t("page_not_found:cta")}
            </Button>
        </div>
    </div>
}

export default PageNotFound
