import {createContext, useState} from "react"

const defaultValue = {
    isOpen: false,
    isLoading: false,
    canClose: true,
    overflow: "auto",
    content: {
        width: null,
        hasCloseIcon: false,
        title: null,
        body: null,
        buttonPrimary: {
            text: null,
            onClick: Function.prototype
        },
        buttonSecondary: {
            text: null,
            onClick: Function.prototype
        }
    },
    open: Function.prototype,
    close: Function.prototype,
    setContent: Function.prototype,
    setIsLoading: Function.prototype
}

export const ModalContext = createContext(defaultValue)

export const ModalProvider = ({children}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [content, setContent] = useState(defaultValue.content)

    const open = () => setIsOpen(true)
    const close = () => setIsOpen(false)

    return (
        <ModalContext.Provider
            value={{
                isOpen,
                isLoading,
                canClose: defaultValue.canClose,
                overflow: defaultValue.overflow,
                ...content,
                open,
                close,
                setContent,
                setIsLoading
            }}
        >
            {children}
        </ModalContext.Provider>
    )
}