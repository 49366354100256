import PublicRoute from "./routeGuards/PublicRoute";
import PageNotFound from "./pages/pageNotFound";
import {Redirect} from "react-router-dom";
import {routes as routenames} from "./helpers/constants"
import RedirectToElevate from "./pages/RedirectToElevate";

const routes = [
    {
        routeType: PublicRoute,
        exact: true,
        path: routenames.QUOTE,
        component: RedirectToElevate, //Home was  replaced
    },
    {
        routeType: PublicRoute,
        exact: true,
        path: routenames.QUOTE_MEDIA,
        component: RedirectToElevate, //Home was  replaced
    },
    /*{
        routeType: PublicRoute,
        exact: true,
        path: routenames.NEW_QUOTE,
        component: NewQuote,
    },*/
    {
        routeType: PublicRoute,
        exact: true,
        path: routenames.PAGE_NOT_FOUND,
        component: PageNotFound,
    },
    {
        routeType: Redirect,
        to: routenames.PAGE_NOT_FOUND,
    }
]

export default routes
