import {createSelector, createSlice} from '@reduxjs/toolkit'
import {themes} from "../helpers/constants"
import {getServiceTheme} from "../helpers/utils"

const shooting = createSlice({
    name: 'shooting',
    initialState: {
        shooting: {},
        service: {
            theme: themes.RED
        }
    },

    reducers: {
        setShooting: (state, action) => {
            const data = action?.payload
            const service = {
                category: data?.quote?.task_category_id || null,
                service: data?.quote?.task_service_id || null,
                media: data?.quote?.task_media_id || null,
                type: data?.entity?.entity_type_id || null
            }

            state.shooting = data
            state.service = {...service, theme: getServiceTheme(service)}
        },
        resetShooting: (state, action) => {
            state.shooting = {}
            state.service = {}
        },
        changeShootStatus: (state, action) => {
            const newStatus = action?.payload

            state.shooting = {
                ...state.shooting,
                assignment: {
                    ...state.shooting.assignment,
                    status: newStatus
                }
            }
        },
        changeQuoteStatus: (state, action) => {
            const newStatus = action?.payload

            state.shooting = {
                ...state.shooting,
                quote: {
                    ...state.shooting.quote,
                    quote_status: newStatus
                }
            }
        },
        changeTaskStatus: (state, action) => {
            const newStatus = action?.payload

            state.shooting = {
                ...state.shooting,
                task_matterport: {
                    ...state.shooting.task_matterport,
                    status: newStatus
                }
            }
        },
        setHostPortrait: (state, action) => {
            state.shooting.quote.host_portrait = action.payload
        }
    }
})

export default shooting.reducer

export const {
    setShooting,
    resetShooting,
    changeShootStatus,
    changeQuoteStatus,
    changeTaskStatus,
    setHostPortrait
} = shooting.actions

const selectSelf = (state) => state.shooting
export const shootingSelector = createSelector(selectSelf, state => state.shooting)
export const shootSelector = createSelector(selectSelf, state => state.shooting.assignment)
export const quoteSelector = createSelector(selectSelf, state => state.shooting.quote)
export const entitySelector = createSelector(selectSelf, state => state.shooting.entity)
export const serviceSelector = createSelector(selectSelf, state => state.service)
export const serviceThemeSelector = createSelector(selectSelf, state => state.service.theme)