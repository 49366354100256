import {createSelector, createSlice} from "@reduxjs/toolkit"

const chatbot = createSlice({
    name: "chatbot",
    initialState: {
        listing: null,
        chatHistory: [],
        sessionId: null
    },

    reducers: {
        setListing: (state, action) => {
            state.listing = action?.payload
        },
        addStepToChatHistory: (state, action) => {
            const {id, source, text} = action.payload
            const stepToAdd = {id, message: `${source}: ${text}`}
            const currentChatHistory = state.chatHistory

            state.chatHistory = [...currentChatHistory, stepToAdd]
        },
        resetChatHistory: (state, action) => {
            state.chatHistory = []
        },
        setSessionId: (state, action) => {
            state.sessionId = action.payload
        }
    }
})

export default chatbot.reducer

export const {
    setListing,
    addStepToChatHistory,
    resetChatHistory,
    setSessionId
} = chatbot.actions

const selectSelf = (state) => state.chatbot
export const chatbotListingSelector = createSelector(selectSelf, state => state.listing)
export const chatbotHistorySelector = createSelector(selectSelf, state => state.chatHistory)
export const sessionIdSelector = createSelector(selectSelf, state => state.sessionId)