import {createSelector, createSlice} from "@reduxjs/toolkit"
import {arrayGroupBy} from "../helpers/utils";
import {hostMatterportActions} from "../helpers/constants";

const matterport = createSlice({
    name: "matterport",
    initialState: {
        autocompletes: {
            amenities: [],
            rooms: []
        },
        currentTask: {
            task: {},
            unchecked: 0,
            uncheckedInitialValue: 0,
            roomsList: [],
            imagesList: [],
            toReviewList: [],
            toReviewListInitialValues: [],
            toDeleteList: [],
            toDeleteListInitialValues: [],
            disableYesToAllButton: true
        }
    },

    reducers: {
        setAmenitiesAutocomplete: (state, action) => {
            const amenities = action.payload
            state.autocompletes.amenities = amenities.map(amenity => ({
                description: amenity?.internal_key,
                label: amenity?.internal_key,
                value: amenity?.id
            }))
        },
        setRoomsAutocomplete: (state, action) => {
            state.autocompletes.rooms = action.payload
        },
        setCurrentTask: (state, action) => {
            const data = action.payload
            //get amenities
            const amenities = state.autocompletes.amenities
            //setup room list
            const roomsList = data?.entity_bedroom?.map(room => ({
                label: room?.name,
                value: room?.id,
                number: room?.number,
                room_id: room?.room_id,
                url: room?.images?.[0]?.url
            }))
            //setup image list obj
            const imagesList = data?.accessibility_images?.map(image => ({
                id: image?.id,
                name: image?.name,
                url: image?.url,
                isOld: !!image?.old,
                selectedRoom: image?.entity_bedroom_id,
                selectedRoomObj: roomsList.find(e => e.value === image?.entity_bedroom_id),
                selectedAmenity: image?.amenity_id,
                selectedAmenityObj: amenities.find(e => e.value === image?.amenity_id),
                hostAction: image?.host_action,
                adminAction: image?.admin_action,
                accepted: false,
                removed: false,
                toCheck: false
            }))
            //group by type
            let toReviewList = imagesList?.filter(image => image?.adminAction === hostMatterportActions.ACCEPTED)
            let toDeleteList = imagesList?.filter(image => image?.adminAction === hostMatterportActions.REMOVED && image?.isOld)
            //array of suggested images grouped by amenities
            let amenityToReview = Array.from(arrayGroupBy(toReviewList, image => image.selectedRoom).values())
            let amenityToDelete = Array.from(arrayGroupBy(toDeleteList, image => image.selectedRoom).values())
            //array of suggested images grouped by amenities and grouped by rooms
            amenityToReview = amenityToReview.map(amenity => Array.from(arrayGroupBy(amenity, amenity => amenity.selectedAmenity).values()))
            amenityToDelete = amenityToDelete.map(amenity => Array.from(arrayGroupBy(amenity, amenity => amenity.selectedAmenity).values()))
            //set unchecked initial value
            let uncheckedUpdated = 0
            amenityToReview.map((amenity) => amenity.map((amenityRooms) => amenityRooms.map((item) => {
                    uncheckedUpdated = (!item.accepted && !item.removed) ? uncheckedUpdated + 1 : uncheckedUpdated
                    return {...item}
                }
            )))
            amenityToDelete.map((amenity) => amenity.map((amenityRooms) => amenityRooms.map((item) => {
                    uncheckedUpdated = (!item.accepted && !item.removed) ? uncheckedUpdated + 1 : uncheckedUpdated
                    return {...item}
                }
            )))

            state.currentTask = {
                task: action.payload,
                unchecked: uncheckedUpdated,
                uncheckedInitialValue: uncheckedUpdated,
                roomsList: roomsList || [],
                imagesList: imagesList || [],
                toReviewList: amenityToReview,
                toReviewListInitialValues: amenityToReview,
                toDeleteList: amenityToDelete,
                toDeleteListInitialValues: amenityToDelete,
                disableYesToAllButton: true
            }
        },
        validateReview: (state, action) => {
            const currentTask = state.currentTask
            const toReviewList = currentTask.toReviewList
            const toDeleteList = currentTask.toDeleteList
            let uncheckedUpdated = 0
            let toReviewListChecked = []
            let toDeleteListChecked = []

            toReviewListChecked = toReviewList.map((amenity) => amenity.map((amenityRooms) => amenityRooms.map((item) => {
                    uncheckedUpdated = (!item.accepted && !item.removed) ? uncheckedUpdated + 1 : uncheckedUpdated
                    return {...item, toCheck: !item.accepted && !item.removed}
                }
            )))

            toDeleteListChecked = toDeleteList.map((amenity) => amenity.map((amenityRooms) => amenityRooms.map((item) => {
                    uncheckedUpdated = (!item.accepted && !item.removed) ? uncheckedUpdated + 1 : uncheckedUpdated
                    return {...item, toCheck: !item.accepted && !item.removed}
                }
            )))

            state.currentTask = {
                ...currentTask,
                unchecked: uncheckedUpdated,
                toReviewList: toReviewListChecked,
                toDeleteList: toDeleteListChecked
            }
        },
        updateMediaReview: (state, action) => {
            const {amenityIndex, roomIndex, imgIndex, type, sectionType} = action.payload
            const currentTask = state.currentTask
            const toReviewList = currentTask.toReviewList
            const toDeleteList = currentTask.toDeleteList
            let uncheckedUpdated = currentTask.unchecked
            let target = sectionType === "review" ? toReviewList[amenityIndex][roomIndex][imgIndex] : toDeleteList[amenityIndex][roomIndex][imgIndex]

            if (sectionType === "review") {
                toReviewList[amenityIndex][roomIndex][imgIndex] = {
                    ...target,
                    accepted: type === hostMatterportActions.ACCEPTED,
                    removed: type === hostMatterportActions.REMOVED,
                    toCheck: false
                }
            } else {
                toDeleteList[amenityIndex][roomIndex][imgIndex] = {
                    ...target,
                    accepted: type === hostMatterportActions.ACCEPTED,
                    removed: type === hostMatterportActions.REMOVED,
                    toCheck: false
                }
            }

            state.currentTask = {
                ...currentTask,
                unchecked: ((!target.removed && !target.accepted) && uncheckedUpdated > 0) ? uncheckedUpdated - 1 : uncheckedUpdated,
                toReviewList: toReviewList,
                toDeleteList: toDeleteList
            }
        },
        approveAllImages: (state, action) => {
            const currentTask = state.currentTask
            const toReviewList = currentTask.toReviewList
            const toDeleteList = currentTask.toDeleteList

            let toReviewListAllAccepted = toReviewList.map((amenity) => amenity.map((amenityRooms) => amenityRooms.map((item) => {
                    return {...item, toCheck: false, accepted: true, removed: false}
                }
            )))

            let toDeleteListAllAccepted = toDeleteList.map((amenity) => amenity.map((amenityRooms) => amenityRooms.map((item) => {
                    return {...item, toCheck: false, accepted: true, removed: false}
                }
            )))

            state.currentTask = {
                ...currentTask,
                unchecked: 0,
                toReviewList: toReviewListAllAccepted,
                toDeleteList: toDeleteListAllAccepted
            }
        },
        resetToInitialState: (state, action) => {
            const currentTask = state.currentTask
            const toReviewListInitial = currentTask.toReviewListInitialValues
            const toDeleteListInitial = currentTask.toDeleteListInitialValues
            const uncheckedInitial = currentTask.uncheckedInitialValue

            state.currentTask = {
                ...currentTask,
                unchecked: uncheckedInitial,
                toReviewList: toReviewListInitial,
                toDeleteList: toDeleteListInitial
            }
        },
        toggleDisableYesToAllButton: (state, action) => {
            state.currentTask.disableYesToAllButton = action.payload
        }
    }
})

export default matterport.reducer

const selectSelf = (state) => state.matterport
export const amenitiesAutocompleteSelector = createSelector(selectSelf, (state) => state.autocompletes.amenities)
export const roomsAutocompleteSelector = createSelector(selectSelf, (state) => state.autocompletes.rooms)
export const uncheckedInitialCountSelector = createSelector(selectSelf, (state) => state.currentTask.uncheckedInitialValue)
export const uncheckedCountSelector = createSelector(selectSelf, (state) => state.currentTask.unchecked)
export const taskSelector = createSelector(selectSelf, (state) => state.currentTask.task)
export const imageToReviewCounter = createSelector(selectSelf, (state) => state.currentTask.imagesList?.filter(image => image?.adminAction === hostMatterportActions.ACCEPTED).length)
export const toDeleteListSelector = createSelector(selectSelf, (state) => state.currentTask.toDeleteList)
export const toReviewListSelector = createSelector(selectSelf, (state) => state.currentTask.toReviewList)
export const disableYesToAllButtonSelector = createSelector(selectSelf, (state) => state.currentTask.disableYesToAllButton)

export const {
    setAmenitiesAutocomplete,
    setRoomsAutocomplete,
    setCurrentTask,
    validateReview,
    updateMediaReview,
    approveAllImages,
    resetToInitialState,
    toggleDisableYesToAllButton
} = matterport.actions


//store manipulation utils
export const imagesObjArraysFlatter = (toReviewList, toDeleteList) => {
    let flatImagesArray = []
    toReviewList.map((amenity) => amenity.map((amenityRooms) => amenityRooms.map((item) => {
            flatImagesArray.push({
                id: item?.id,
                action: item?.accepted ? "accepted" : "removed",
                old: item?.isOld
            })
            return item
        }
    )))
    toDeleteList.map((amenity) => amenity.map((amenityRooms) => amenityRooms.map((item) => {
            flatImagesArray.push({
                id: item?.id,
                action: item?.accepted ? "accepted" : "removed",
                old: item?.isOld
            })
            return item
        }
    )))
    return flatImagesArray
}