import {useTheme} from "react-jss"

export const CloseIcon = (props = {}) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
    </svg>
)

export const HomeIcon = (props = {}) => (
    <svg viewBox="0 0 25 24" {...props}>
        <path
            d="M12.5 5.69L17.7084 10.19V18H15.625V12H9.37504V18H7.29171V10.19L12.5 5.69ZM12.5 3L2.08337 12H5.20837V20H11.4584V14H13.5417V20H19.7917V12H22.9167L12.5 3Z"/>
    </svg>
)

export const CameraIcon = (props = {}) => (
    <svg viewBox="0 0 23 22" fill="none" {...props}>
        <path
            d="M12.8026 0C13.1899 0 13.5631 0.135658 13.8576 0.380448L13.9643 0.477307L15.948 2.44444H18.8929C21.0401 2.44444 22.8025 4.07899 22.9845 6.16217L22.9962 6.34179L23 6.51852V17.9259C23 20.1168 21.2567 21.9037 19.071 21.9962L18.8929 22H4.10714C1.89852 22 0.0970781 20.2707 0.00379467 18.1027L0 17.9259V6.51852C0 4.32768 1.74332 2.54074 3.92898 2.44821L4.10714 2.44444H7.07579L9.03207 0.483526C9.27209 0.242869 9.57982 0.0840638 9.91119 0.0253682L10.0545 0.00639527L10.2 0H12.8026ZM12.8026 1.62963H10.2L7.76208 4.07407H4.10714C2.79476 4.07407 1.72199 5.09171 1.64704 6.37489L1.64286 6.51852V17.9259C1.64286 19.2277 2.66876 20.2919 3.96235 20.3662L4.10714 20.3704H18.8929C20.2052 20.3704 21.278 19.3527 21.353 18.0696L21.3571 17.9259V6.51852C21.3571 5.2167 20.3312 4.15257 19.0377 4.07822L18.8929 4.07407H15.2669L12.8026 1.62963ZM11.5 5.7037C15.1293 5.7037 18.0714 8.62214 18.0714 12.2222C18.0714 15.8223 15.1293 18.7407 11.5 18.7407C7.8707 18.7407 4.92857 15.8223 4.92857 12.2222C4.92857 8.62214 7.8707 5.7037 11.5 5.7037ZM11.5 7.33333C8.77803 7.33333 6.57143 9.52216 6.57143 12.2222C6.57143 14.9223 8.77803 17.1111 11.5 17.1111C14.222 17.1111 16.4286 14.9223 16.4286 12.2222C16.4286 9.52216 14.222 7.33333 11.5 7.33333ZM4.10714 5.7037C4.56081 5.7037 4.92857 6.06851 4.92857 6.51852C4.92857 6.96853 4.56081 7.33333 4.10714 7.33333C3.65348 7.33333 3.28571 6.96853 3.28571 6.51852C3.28571 6.06851 3.65348 5.7037 4.10714 5.7037Z"/>
    </svg>
)

export const MembersIcon = (props = {}) => (
    <svg viewBox="0 0 27 27" fill="none" {...props}>
        <path
            d="M13.5 0C20.9558 0 27 6.04416 27 13.5C27 20.9558 20.9558 27 13.5 27C6.04416 27 0 20.9558 0 13.5C0 6.04416 6.04416 0 13.5 0ZM13.5 1.22727C6.72196 1.22727 1.22727 6.72196 1.22727 13.5C1.22727 20.278 6.72196 25.7727 13.5 25.7727C20.278 25.7727 25.7727 20.278 25.7727 13.5C25.7727 6.72196 20.278 1.22727 13.5 1.22727ZM17.1818 6.75C18.8763 6.75 20.25 8.12367 20.25 9.81818C20.25 10.7067 19.8723 11.507 19.2687 12.067C21.2312 12.8685 22.6294 14.7667 22.7016 17L22.7045 17.1818H21.4773L21.4748 17.0341C21.4057 14.988 19.9055 13.3048 17.9435 12.9537L17.7955 12.9299L17.7954 11.5544C18.5104 11.3017 19.0227 10.6197 19.0227 9.81818C19.0227 8.80148 18.1985 7.97727 17.1818 7.97727C16.2014 7.97727 15.4 8.74366 15.344 9.71001L15.3409 9.81818C16.0862 10.3778 16.5682 11.269 16.5682 12.2727C16.5682 13.4149 15.9441 14.4113 15.0183 14.9395C17.2801 15.5838 18.9494 17.6351 19.0204 20.0872L19.0227 20.25H17.7955C17.7955 18.0862 16.1956 16.2962 14.1143 15.9981L14.1142 14.0087C14.8289 13.7558 15.3409 13.0741 15.3409 12.2727C15.3409 11.256 14.5167 10.4318 13.5 10.4318C12.4833 10.4318 11.6591 11.256 11.6591 12.2727C11.6591 13.0743 12.1714 13.7562 12.8864 14.0089L12.8864 15.998C10.8523 16.289 9.27789 18.005 9.20704 20.1023L9.20455 20.25H7.97727C7.97727 17.7263 9.67002 15.598 11.9821 14.9385C11.0559 14.4113 10.4318 13.4149 10.4318 12.2727C10.4318 11.2917 10.8922 10.4182 11.6088 9.85655L11.6591 9.81818C11.6591 8.80148 10.8349 7.97727 9.81818 7.97727C8.80148 7.97727 7.97727 8.80148 7.97727 9.81818C7.97727 10.6197 8.48957 11.3017 9.2046 11.5544L9.20455 12.9299C7.17045 13.2208 5.59607 14.9369 5.52522 17.0341L5.52273 17.1818H4.29545C4.29545 14.8702 5.71573 12.8902 7.73127 12.067C7.12766 11.507 6.75 10.7067 6.75 9.81818C6.75 8.12367 8.12367 6.75 9.81818 6.75C11.3278 6.75 12.5828 7.84025 12.8387 9.27632C13.0511 9.22927 13.2727 9.20455 13.5 9.20455C13.7273 9.20455 13.9489 9.22927 14.1621 9.27618C14.4172 7.84025 15.6722 6.75 17.1818 6.75Z"/>
    </svg>
)

export const ShowMoreIcon = (props = {}) => (
    <svg viewBox="0 0 16 10" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M2 0L8 6L14 0L16 2L8 10L0 2L2 0Z"/>
    </svg>
)

export const InfoIcon = ({color = '#A9A9A9', ...props}) => (
    <svg
        viewBox='0 0 24 24'
        {...props}
    >
        <path
            d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z'
            fill={color}
        />
    </svg>
)

export const LanguageIcon = (props = {}) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            d="m12.87 15.07-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7 1.62-4.33L19.12 17h-3.24z"/>
    </svg>
)

export const ProfessionalEyeIcon = (props = {}) => {
    const theme = useTheme()

    return (
        <svg viewBox="0 0 48 48" fill="none" {...props}>
            <rect width="48" height="48" fill="white"/>
            <path
                d="M29.6784 18.1842L24 8.10889L18.3216 18.1842L6.98499 20.4709L14.8121 28.9852L13.484 40.4729L24 35.6606L34.515 40.4729L33.1879 28.9852L41.014 20.4709L29.6784 18.1842Z"
                fill={theme.themeColor}
                fillOpacity="0.2"
            />
            <path
                d="M23.1202 1.52444L15.736 14.624L0.99427 17.5998C0.46801 17.6558 0.0867882 18.1279 0.142788 18.6541C0.163141 18.8454 0.240586 19.0261 0.365048 19.1728L0.450485 19.2734L10.628 30.343L8.90182 45.284L8.90006 45.2989C8.83548 45.8474 9.22777 46.3444 9.77627 46.409C9.95225 46.4297 10.1306 46.4033 10.293 46.3325L10.3255 46.3183L24 40.06L37.7991 46.3753C38.2696 46.5867 38.8224 46.3767 39.0338 45.9062C39.0994 45.7602 39.1264 45.5999 39.1122 45.4405L39.0982 45.284L37.371 30.343L47.5496 19.2734L47.6578 19.1278C47.7234 19.0325 47.7396 18.9785 47.7961 18.7896L47.7842 18.6464C47.7266 17.975 47.6842 17.953 47.0058 17.5998L32.263 14.624L24.8799 1.52444C24.6774 1.17481 24.3841 1 24 1C23.6159 1 23.3226 1.17481 23.1202 1.52444ZM17.0287 16.4049L24 4.036L30.9713 16.4049L44.888 19.213L35.2798 29.665L36.909 43.768L24 37.8602L11.09 43.768L12.7203 29.665L3.11102 19.213L17.0287 16.4049Z"
                fill={theme.themeColor}
            />
        </svg>
    )
}

export const HighQualityIcon = (props = {}) => {
    const theme = useTheme()

    return (
        <svg viewBox="0 0 40 38" fill="none" {...props}>
            <path
                d="M21.757 4H18.242L14.2426 8H5C4.48716 8 4.06449 8.38604 4.00673 8.88338L4 9V33C4 33.5128 4.38604 33.9355 4.88338 33.9933L5 34H35C35.5128 34 35.9355 33.614 35.9933 33.1166L36 33V9C36 8.48716 35.614 8.06449 35.1166 8.00673L35 8H25.7574L21.757 4Z"
                fill={theme.themeColor}
                fillOpacity="0.2"
            />
            <path
                d="M22.5858 0C23.0573 0 23.5116 0.16649 23.8702 0.466913L24 0.585786L27.415 4H35C37.6141 4 39.7596 6.00604 39.9811 8.56266L39.9954 8.78311L40 9V33C40 35.6888 37.8777 37.8818 35.2169 37.9954L35 38H5C2.31125 38 0.118182 35.8777 0.0046196 33.2169L0 33V9C0 6.31125 2.12231 4.11818 4.78311 4.00462L5 4H12.584L16 0.585786C16.2917 0.294063 16.6643 0.101767 17.065 0.0307083L17.2384 0.0077405L17.4142 0H22.5858ZM22.5858 2H17.4142L13.4142 6H5C3.40232 6 2.09634 7.24892 2.00509 8.82373L2 9V33C2 34.5977 3.24892 35.9037 4.82373 35.9949L5 36H35C36.5977 36 37.9037 34.7511 37.9949 33.1763L38 33V9C38 7.40232 36.7511 6.09634 35.1763 6.00509L35 6H26.5858L22.5858 2ZM20 14C23.866 14 27 17.134 27 21C27 24.866 23.866 28 20 28C16.134 28 13 24.866 13 21C13 17.134 16.134 14 20 14ZM20 16C17.2386 16 15 18.2386 15 21C15 23.7614 17.2386 26 20 26C22.7614 26 25 23.7614 25 21C25 18.2386 22.7614 16 20 16ZM7 10C7.55228 10 8 10.4477 8 11C8 11.5523 7.55228 12 7 12C6.44772 12 6 11.5523 6 11C6 10.4477 6.44772 10 7 10Z"
                fill={theme.themeColor}
            />
        </svg>
    )
}

export const VideoTrailerIcon = (props = {}) => {
    const theme = useTheme()

    return (
        <svg viewBox="0 0 51 52" fill="none" {...props}>
            <path
                d="M25.5 0C39.5833 0 51 11.6406 51 26C51 40.3594 39.5833 52 25.5 52C11.4167 52 0 40.3594 0 26C0 11.6406 11.4167 0 25.5 0ZM25.5 3.46667C13.2945 3.46667 3.4 13.5552 3.4 26C3.4 38.4448 13.2945 48.5333 25.5 48.5333C37.7055 48.5333 47.6 38.4448 47.6 26C47.6 13.5552 37.7055 3.46667 25.5 3.46667ZM17 15.6C17 14.3201 18.301 13.505 19.4026 14.021L19.5746 14.1137L36.5746 24.5137C37.6104 25.1473 37.6713 26.6251 36.7574 27.358L36.5746 27.4863L19.5746 37.8863C18.4982 38.5448 17.1434 37.8267 17.0106 36.5981L17 36.4V15.6ZM20.4 18.6611V33.3372L32.3952 26L20.4 18.6611Z"
                fill={theme.themeColor}
            />
        </svg>
    )
}

export const UpToIcon = (props = {}) => {
    const theme = useTheme()

    return (
        <svg viewBox="0 0 48 48" fill="none" {...props}>
            <rect width="48" height="48" fill="white"/>
            <path
                d="M24.018 6.55713L41.395 17.5061L24.261 28.4281L7.08203 17.4771L24.018 6.55713Z"
                fill={theme.themeColor}
                fillOpacity="0.2"
                fillRule="evenodd"
                clipRule="evenodd"
            />
            <path
                d="M22.9224 2.50515C23.5257 2.11617 24.2866 2.08252 24.9175 2.4057L25.0724 2.49393L46.1982 15.8052C47.1321 16.394 47.4129 17.629 46.8241 18.5635C46.6982 18.7633 46.5381 18.9388 46.3517 19.0823L46.2071 19.1838L42.5555 21.51L46.1984 23.8053C47.1321 24.3943 47.4129 25.6291 46.8241 26.5635C46.6982 26.7633 46.5381 26.9388 46.3517 27.0823L46.2071 27.1838L44.1335 28.504L46.1984 29.8053C47.1321 30.3943 47.4129 31.6291 46.8241 32.5635C46.6982 32.7633 46.5381 32.9388 46.3517 33.0823L46.2071 33.1838L25.3371 46.4879C24.7318 46.8738 23.9706 46.9035 23.3413 46.577L23.1869 46.4879L2.30169 33.1741C1.37028 32.5803 1.09655 31.3439 1.69031 30.4125C1.81423 30.2181 1.97058 30.047 2.15216 29.9063L2.29297 29.8067L4.34953 28.479L2.30169 27.1741C1.37028 26.5803 1.09655 25.3439 1.69031 24.4125C1.81423 24.2181 1.97058 24.047 2.15216 23.9063L2.29297 23.8067L5.90953 21.474L2.30169 19.1741C1.41685 18.61 1.12557 17.4659 1.60772 16.5544L1.69031 16.4125C1.81423 16.2181 1.97058 16.047 2.15216 15.9063L2.29297 15.8067L22.9224 2.50515ZM6.20553 29.663L3.37678 31.4876L24.262 44.8015L45.132 31.4973L42.2685 29.693L25.3371 40.4879C24.7318 40.8738 23.9706 40.9035 23.3413 40.577L23.1869 40.4879L6.20553 29.663ZM7.76553 22.657L3.37678 25.4876L24.262 38.8015L45.132 25.4973L40.6915 22.699L25.3371 32.4879C24.7318 32.8738 23.9706 32.9035 23.3413 32.577L23.1869 32.4879L7.76553 22.657ZM24.0062 4.18604L3.37678 17.4876L24.262 30.8015L40.6095 20.38L40.6527 20.311L40.6855 20.331L45.132 17.4973L24.0062 4.18604Z"
                fill={theme.themeColor}
            />
        </svg>
    )
}

export const DeclinedQuoteIcon = (props = {}) => {
    const theme = useTheme()

    return (
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M33 60C47.9117 60 60 47.9117 60 33C60 18.0883 47.9117 6 33 6C18.0883 6 6 18.0883 6 33C6 47.9117 18.0883 60 33 60Z"
                fill={theme.themeColor}
                fillOpacity="0.2"
            />
            <path
                d="M33 0C51.2254 0 66 14.7746 66 33C66 51.2254 51.2254 66 33 66C14.7746 66 0 51.2254 0 33C0 14.7746 14.7746 0 33 0ZM33 3C16.4315 3 3 16.4315 3 33C3 49.5685 16.4315 63 33 63C49.5685 63 63 49.5685 63 33C63 16.4315 49.5685 3 33 3ZM21.3327 19.2114L33.03 30.909L44.6985 19.2426L46.8198 21.364L35.1525 33.0315L46.7886 44.6673L44.6673 46.7886L33.0315 35.1525L21.364 46.8198L19.2426 44.6985L30.909 33.03L19.2114 21.3327L21.3327 19.2114Z"
                fill={theme.themeColor}
            />
        </svg>
    )
}

export const ExpiredQuoteIcon = (props = {}) => {
    const theme = useTheme()

    return (
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M33 6C33.5037 6 34.0041 6.01379 34.501 6.04102L34.5 32.4L53.1136 51.0127C48.17 56.5291 40.9904 60 33 60C18.0883 60 6 47.9117 6 33C6 18.0883 18.0883 6 33 6Z"
                fill={theme.themeColor}
                fillOpacity="0.2"
                fillRule="evenodd"
                clipRule="evenodd"
            />
            <path
                d="M33 0C51.2254 0 66 14.7746 66 33C66 51.2254 51.2254 66 33 66C14.7746 66 0 51.2254 0 33C0 14.7746 14.7746 0 33 0ZM33 3C16.4315 3 3 16.4315 3 33C3 49.5685 16.4315 63 33 63C49.5685 63 63 49.5685 63 33C63 16.4315 49.5685 3 33 3ZM34.5 12L34.4985 32.376L49.0607 46.9393L46.9393 49.0607L31.5 33.6213V12H34.5Z"
                fill={theme.themeColor}
            />
        </svg>
    )
}

export const PageNotFoundIcon = (props = {}) => {
    const theme = useTheme()

    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="72" height="72" fill="white"/>
            <path
                d="M30 1.5C45.7401 1.5 58.5 14.2599 58.5 30C58.5 37.33 55.7328 44.0137 51.1861 49.0634L70.0607 67.9393L67.9393 70.0607L49.0634 51.1861C44.0137 55.7328 37.33 58.5 30 58.5C14.2599 58.5 1.5 45.7401 1.5 30C1.5 14.2599 14.2599 1.5 30 1.5ZM30 4.5C15.9167 4.5 4.5 15.9167 4.5 30C4.5 44.0833 15.9167 55.5 30 55.5C44.0833 55.5 55.5 44.0833 55.5 30C55.5 15.9167 44.0833 4.5 30 4.5Z"
                fill={theme.themeColor}
            />
            <path
                d="M30 52.5C42.4264 52.5 52.5 42.4264 52.5 30C52.5 17.5736 42.4264 7.5 30 7.5C17.5736 7.5 7.5 17.5736 7.5 30C7.5 42.4264 17.5736 52.5 30 52.5Z"
                fill={theme.themeColor}
                fillOpacity="0.2"
            />
        </svg>
    )
}

export const ChatBotIcon = (props = {}) => {
    const theme = useTheme()
    return (
        <svg viewBox="0 0 48 48" fill="#fff" {...props}>
            <path
                d="m25.75 44-.5-5.5h-2q-7.15 0-12.2-5.05Q6 28.4 6 21.25q0-7.15 5.075-12.2Q16.15 4 23.35 4q3.55 0 6.575 1.275Q32.95 6.55 35.2 8.85q2.25 2.3 3.525 5.425T40 21.1q0 3.3-.975 6.6T36.2 34q-1.85 3-4.5 5.55T25.75 44Zm2.5-5.4q4.1-3.45 6.425-8.125T37 21.1q0-6.2-3.875-10.15T23.35 7q-6 0-10.175 4.175Q9 15.35 9 21.25q0 5.9 4.175 10.075Q17.35 35.5 23.25 35.5h5Zm-4.9-5.75q.8 0 1.35-.55t.55-1.35q0-.8-.55-1.35t-1.35-.55q-.8 0-1.35.55t-.55 1.35q0 .8.55 1.35t1.35.55ZM22 26.05h2.5q0-1.25.425-2.075.425-.825 1.775-2.175 1.35-1.35 1.9-2.475.55-1.125.55-2.425 0-2.25-1.525-3.7-1.525-1.45-4.075-1.45-2.1 0-3.75 1.1t-2.45 3l2.3.95q.55-1.3 1.525-1.925.975-.625 2.225-.625 1.5 0 2.35.725.85.725.85 1.925 0 .95-.55 1.925-.55.975-1.95 2.425-1.35 1.4-1.725 2.25-.375.85-.375 2.55Zm1-3.25Z"/>
        </svg>
    )
}
export const ArrowUpIcon = (props = {}) => <svg viewBox="0 0 8 4" fill="none" {...props}>
    <path
        d="M4.00627 0.00118273C4.13956 0.000954812 4.26872 0.0416212 4.37135 0.116123L7.79393 2.61483C7.91042 2.69966 7.98368 2.82155 7.99759 2.95369C8.01149 3.08584 7.96491 3.21741 7.86809 3.31947C7.77126 3.42152 7.63213 3.4857 7.48129 3.49789C7.33045 3.51007 7.18027 3.46926 7.06378 3.38443L4.00627 1.14559L0.948768 3.30447C0.89042 3.34599 0.823283 3.37699 0.751215 3.39569C0.679147 3.4144 0.60357 3.42044 0.528828 3.41348C0.454085 3.40651 0.38165 3.38667 0.315688 3.3551C0.249725 3.32353 0.191535 3.28085 0.144461 3.22951C0.0922213 3.17813 0.0526572 3.11785 0.0282469 3.05244C0.00383668 2.98704 -0.00489229 2.91793 0.00260455 2.84943C0.0101019 2.78093 0.0336638 2.71453 0.0718161 2.65438C0.109968 2.59424 0.161889 2.54164 0.224322 2.49989L3.6469 0.0861389C3.75248 0.0234133 3.87901 -0.00649865 4.00627 0.00118273Z"
        fill="black"/>
</svg>


export const ErrorIcon = (props = {}) => <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
</svg>

export const DoneIcon = (props = {}) => <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M0 0h24v24H0z" fill="none"/>
    <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
</svg>

export const SuccessIcon = (props = {}) => {
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" {...props}>
            <rect width="72" height="72" fill="white"/>
            <path d="M9 36C9 21.15 21.15 9 36 9C50.85 9 63 21.15 63 36C63 50.85 50.85 63 36 63C21.15 63 9 50.85 9 36Z"
                  fill="#E31C5F" fillOpacity="0.2"/>
            <path
                d="M36 3C17.85 3 3 17.85 3 36C3 54.15 17.85 69 36 69C54.15 69 69 54.15 69 36C69 17.85 54.15 3 36 3ZM36 66C19.5 66 6 52.5 6 36C6 19.5 19.5 6 36 6C52.5 6 66 19.5 66 36C66 52.5 52.5 66 36 66ZM51.75 23.4L53.85 25.5L30.3 49.05L18 36.75L20.1 34.65L30.3 44.85L51.75 23.4Z"
                fill="#E31C5F"/>
        </svg>
    )
}

export const ScrollArrow = ({...rest}) => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" {...rest}>
            <path
                d="M58.125 30C58.125 45.533 45.533 58.125 30 58.125C14.467 58.125 1.875 45.533 1.875 30C1.875 14.467 14.467 1.875 30 1.875C45.533 1.875 58.125 14.467 58.125 30ZM54.375 30C54.375 16.5381 43.4619 5.625 30 5.625C16.5381 5.625 5.625 16.5381 5.625 30C5.625 43.4619 16.5381 54.375 30 54.375C43.4619 54.375 54.375 43.4619 54.375 30ZM44.4508 33.2522L31.7078 45.9953C30.6037 47.0993 28.8543 47.1642 27.6744 46.1901L27.4601 45.9953L14.717 33.2522L17.3687 30.6005L27.7106 40.9425L27.7089 15H31.4589L31.4606 40.9388L41.7992 30.6005L44.4508 33.2522Z"
                fill="black"/>
        </svg>
    )
}

export const VeryPoorIcon = ({...rest}) => {
    return <svg viewBox="0 0 48 48" width="40" height="40" fill="none" {...rest}>
        <path
            d="M24 27.15q-3.35 0-6.075 1.875T13.9 34h20.2q-1.25-3.15-4-5T24 27.15Zm-9.15-3.6 2.5-2.25 2.25 2.25 1.55-1.8-2.25-2.25 2.25-2.25-1.55-1.8-2.25 2.25-2.5-2.25-1.55 1.8 2.25 2.25-2.25 2.25Zm13.6 0 2.2-2.25 2.55 2.25 1.55-1.8-2.25-2.25 2.25-2.25-1.55-1.8-2.55 2.25-2.2-2.25-1.55 1.8 2.2 2.25-2.2 2.25ZM24 44q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 28.15 4 24t1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4t7.8 1.575q3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24t-1.575 7.8q-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 44 24 44Zm0-20Zm0 17q7.1 0 12.05-4.95Q41 31.1 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.1 0-12.05 4.95Q7 16.9 7 24q0 7.1 4.95 12.05Q16.9 41 24 41Z"/>
    </svg>
}

export const PoorIcon = ({...rest}) => {
    return <svg viewBox="0 0 48 48" width="40" height="40" {...rest}>
        <path
            d="M31.3 21.35q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm-14.6 0q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm7.3 5.8q-3.35 0-6.075 1.875T13.9 34h2.65q1.1-2.1 3.1-3.25t4.4-1.15q2.35 0 4.325 1.175T31.5 34h2.6q-1.25-3.15-4-5T24 27.15ZM24 44q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 28.15 4 24t1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4t7.8 1.575q3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24t-1.575 7.8q-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 44 24 44Zm0-20Zm0 17q7.1 0 12.05-4.95Q41 31.1 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.1 0-12.05 4.95Q7 16.9 7 24q0 7.1 4.95 12.05Q16.9 41 24 41Z"/>
    </svg>
}


export const AverageIcon = ({...rest}) => {
    return <svg viewBox="0 0 48 48" height="40" width="40" {...rest}>
        <path
            d="M31.3 21.35q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm-14.6 0q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm1 9.7h12.65V28.6H17.7ZM24 44q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 28.15 4 24t1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4t7.8 1.575q3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24t-1.575 7.8q-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 44 24 44Zm0-20Zm0 17q7.1 0 12.05-4.95Q41 31.1 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.1 0-12.05 4.95Q7 16.9 7 24q0 7.1 4.95 12.05Q16.9 41 24 41Z"/>
    </svg>
}

export const GoodIcon = ({...rest}) => {
    return <svg viewBox="0 0 48 48" height="40" width="40" {...rest}>
        <path
            d="M31.3 21.35q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm-14.6 0q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm7.3 13.6q3.3 0 6.075-1.775Q32.85 31.4 34.1 28.35h-2.6q-1.15 2-3.15 3.075-2 1.075-4.3 1.075-2.35 0-4.375-1.05t-3.125-3.1H13.9q1.3 3.05 4.05 4.825Q20.7 34.95 24 34.95ZM24 44q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 28.15 4 24t1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4t7.8 1.575q3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24t-1.575 7.8q-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 44 24 44Zm0-20Zm0 17q7.1 0 12.05-4.95Q41 31.1 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.1 0-12.05 4.95Q7 16.9 7 24q0 7.1 4.95 12.05Q16.9 41 24 41Z"/>
    </svg>
}

export const ExcellentIcon = ({...rest}) => {
    return <svg viewBox="0 0 48 48" height="40" width="40" {...rest}>
        <path
            d="M24 34.95q3.3 0 6.075-1.775Q32.85 31.4 34.1 28.35H13.9q1.3 3.05 4.05 4.825Q20.7 34.95 24 34.95Zm-8.9-13.6 2.25-2.25 2.25 2.25 1.8-1.8-4.05-4.05-4.05 4.05Zm13.35 0 2.25-2.25 2.25 2.25 1.8-1.8-4.05-4.05-4.05 4.05ZM24 44q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 28.15 4 24t1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4t7.8 1.575q3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24t-1.575 7.8q-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 44 24 44Zm0-20Zm0 17q7.1 0 12.05-4.95Q41 31.1 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.1 0-12.05 4.95Q7 16.9 7 24q0 7.1 4.95 12.05Q16.9 41 24 41Z"/>
    </svg>
}

export const ShineIcon = ({...rest}) => {
    return <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M8.74993 0C8.74993 6.33355 5.32716 11.9999 0.5 11.9999C5.32716 11.9999 8.74993 17.6667 8.74993 23.9998C8.74993 17.6667 12.1727 11.9999 16.9999 11.9999C12.1727 11.9999 8.74993 6.33355 8.74993 0Z" fill="#18794E"/>
        <path d="M18.4997 12C18.4997 15.1672 16.7889 17.9999 14.3745 17.9999C16.7889 17.9999 18.4997 20.8335 18.4997 23.9999C18.4997 20.8335 20.2105 17.9999 22.6248 17.9999C20.2105 17.9999 18.4997 15.1672 18.4997 12Z" fill="#18794E"/>
        <path d="M20.3752 0C20.3752 3.16677 18.6644 5.99995 16.25 5.99995C18.664 5.99995 20.3752 8.83353 20.3752 11.9999C20.3752 8.83353 22.0859 5.99995 24.5003 5.99995C22.0859 5.99995 20.3752 3.16677 20.3752 0Z" fill="#18794E"/>
    </svg>
}

export const MapPinIcon = ({...rest}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" {...rest}>
        <g clipPath="url(#clip0_4811_28691)">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.28579 4.11929C6.53603 2.86905 8.23173 2.16667 9.99984 2.16667C11.7679 2.16667 13.4636 2.86905 14.7139 4.11929C15.9641 5.36953 16.6665 7.06522 16.6665 8.83333C16.6665 11.415 14.9867 13.9202 13.1449 15.8644C12.241 16.8185 11.3343 17.5996 10.6526 18.1424C10.3989 18.3444 10.1773 18.5127 9.99984 18.6438C9.82236 18.5127 9.60077 18.3444 9.34707 18.1424C8.66539 17.5996 7.75868 16.8185 6.8548 15.8644C5.01295 13.9202 3.33317 11.415 3.33317 8.83333C3.33317 7.06522 4.03555 5.36953 5.28579 4.11929ZM9.53714 20.3597C9.53738 20.3599 9.53759 20.36 9.99984 19.6667L9.53759 20.36C9.8175 20.5467 10.1822 20.5467 10.4621 20.36L9.99984 19.6667C10.4621 20.36 10.4623 20.3599 10.4625 20.3597L10.4632 20.3593L10.4649 20.3581L10.4705 20.3544L10.4896 20.3415C10.5058 20.3305 10.5289 20.3147 10.5585 20.2942C10.6177 20.2532 10.7028 20.1934 10.81 20.116C11.0244 19.9612 11.3279 19.7352 11.6908 19.4462C12.4154 18.8692 13.3837 18.0357 14.3548 17.0106C16.263 14.9965 18.3332 12.085 18.3332 8.83333C18.3332 6.6232 17.4552 4.50358 15.8924 2.94078C14.3296 1.37797 12.21 0.5 9.99984 0.5C7.7897 0.5 5.67008 1.37797 4.10728 2.94078C2.54448 4.50358 1.6665 6.6232 1.6665 8.83333C1.6665 12.085 3.73672 14.9965 5.64488 17.0106C6.61599 18.0357 7.58428 18.8692 8.30886 19.4462C8.6718 19.7352 8.97529 19.9612 9.18966 20.116C9.29689 20.1934 9.38195 20.2532 9.44114 20.2942C9.47073 20.3147 9.49388 20.3305 9.5101 20.3415L9.5292 20.3544L9.53475 20.3581L9.53651 20.3593L9.53714 20.3597ZM8.33317 8.83333C8.33317 7.91286 9.07936 7.16667 9.99984 7.16667C10.9203 7.16667 11.6665 7.91286 11.6665 8.83333C11.6665 9.75381 10.9203 10.5 9.99984 10.5C9.07936 10.5 8.33317 9.75381 8.33317 8.83333ZM9.99984 5.5C8.15889 5.5 6.6665 6.99238 6.6665 8.83333C6.6665 10.6743 8.15889 12.1667 9.99984 12.1667C11.8408 12.1667 13.3332 10.6743 13.3332 8.83333C13.3332 6.99238 11.8408 5.5 9.99984 5.5Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_4811_28691">
                <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
        </defs>
    </svg>
}
