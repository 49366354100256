import chatbot from "./chatbot.json"
import common from "./common.json"
import declined from "./declined.json"
import expired from "./expired.json"
import header from "./header.json"
import quote from "./quote.json"


export default {
	chatbot,
	common,
	declined,
	expired,
	header,
	quote
}
