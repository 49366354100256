import React from 'react'
import ReactDOM from 'react-dom'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './index.css'
import "./i18n"
import App from './App'
import reportWebVitals from './reportWebVitals'
import appGenericsReducer from "./store/appGenerics"
import shootingReducer from "./store/shooting"
import matterportReducer from "./store/matterport"
import chatbotReducer from "./store/chatbot"
import { configureStore } from "@reduxjs/toolkit"
import { Provider } from "react-redux"
import * as axios from "axios"
import TagManager from 'react-gtm-module'
import {ModalProvider} from "./contexts/Modal"

const tagManagerArgs = {
    gtmId: 'GTM-PBGGND4'
}

TagManager.initialize(tagManagerArgs)

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT

const reducer = {
    appGenerics: appGenericsReducer,
    shooting: shootingReducer,
    matterport: matterportReducer,
    chatbot: chatbotReducer
}
export const store = configureStore({reducer})

ReactDOM.render(
    <Provider store={store}>
        <ModalProvider>
            <App />
        </ModalProvider>
    </Provider>
    ,
    document.getElementById('root')
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
